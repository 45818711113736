



























import Vue from "vue";
import {
    mapActions,
    mapState
} from "vuex";
import { InviteService } from "@web/services/intranet-access";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { MailInviteInFireStoreFieldKeys } from "@web/store/invite/types";
import { INVITE_MODULE_NAME } from "@web/store/invite/invite";
import { REVOKE_INVITE } from "@web/store/invite/actions";

import copy from "clipboard-copy";
import { toDayJs } from "@web/lib/time-utils";

    declare interface ComponentData {
    }

export default Vue.extend<any, any, any, any>({
    name: "InviteRow",
    components: {},
    props: {
        invite: {
            type: Object,
            default: false
        }
    },
    data(): ComponentData {
        return {};
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        inviteUrl() {
            return InviteService.buildInviteUrl(this.invite, this.intranet.uid);
        },
        invitationDate() {
            return toDayJs(this.invite[MailInviteInFireStoreFieldKeys.invitationDate]).format("DD. MMM. YYYY");
        },
    },
    methods: {
        ...mapActions({
            revoke: INVITE_MODULE_NAME + REVOKE_INVITE,
        }),
        async revokeInvite(inviteId: string) {
            const text = this.$t("invite_revoked").toString();
            await this.revoke(inviteId);
            this.$notify({
                group: "app",
                type: "success",
                text
            });
        },
        copyToClipboard(text: string) {
            copy(text).then(() => {
                this.$notify({
                    group: "app",
                    type: "success",
                    text: this.$t("copied_clipboard").toString(),
                });

                console.log("copied");
            });
        }

    }
});

