



























import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    FETCH_INVITES,
    INVITE_USER_BY_MAIL
} from "@web/store/invite/actions";
import { INVITE_MODULE_NAME } from "@web/store/invite/invite";
import { InviteService } from "@web/services/intranet-access";
import Vue from "vue";
import {
    mapActions,
    mapMutations,
    mapState
} from "vuex";
import { RESET_INVITE_MODULE } from "@web/store/invite/mutations";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export default Vue.extend({
    name: "MailInvite",
    components: {},
    data() {
        return {
            mail: "",
            error: "",
            msg: "",
            inviteUrl: "",
            loading: false,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"])
    },
    methods: {
        ...mapActions(
            {
                inviteByMail: INVITE_MODULE_NAME + INVITE_USER_BY_MAIL,
                fetchInvites: INVITE_MODULE_NAME + FETCH_INVITES,
            },
        ),
        ...mapMutations({
            reset: INVITE_MODULE_NAME + RESET_INVITE_MODULE,
        }),
        async tryInvite() {
            this.error = "";
            this.msg = "";
            this.inviteUrl = "";
            this.loading = true;
            try {
                const { data, status } = await this.inviteByMail(this.mail);
                this.inviteUrl = InviteService.buildInviteUrl(
                    data,
                    this.intranet.uid
                );
                if (status === 200) {
                    this.msg = this.$t("successful_invite_with_existing_sharing_link").toString();
                }
                if (status === 201) {
                    this.msg = this.$t("successful_invite").toString();
                    this.reset();
                    this.fetchInvites();
                    analytics.log(getGlobalConfiguration().analytics_event_name_invite_created);
                }
            } catch (e) {
                if (e.response.status === 409) {
                    this.error = this.$t("error_invite_already_in_intranet").toString();
                    return;
                }
                this.error = this.$t("error_invite_with_sharing_link").toString();
            } finally {
                this.loading = false;
            }
        },
    }
});
