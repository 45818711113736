<template>
    <div>
        <transition-expand>
            <loading-wrapper
                :is-loading="loadingAccessCode"
                class="loading-wrapper"
            >
                <p class="mbottom-xxsmall">
                    <strong>{{ $t("add_people_easy_access_title") }}</strong>
                </p>
                <p>
                    {{ easyAccessDescription }}
                    <router-link
                        :to="{ name: 'user-access' }"
                        target="_blank"
                    >
                        <a class="link">{{ $t("administration_settings") }}</a>
                    </router-link>.
                </p>
                <app-button
                    v-if="accessCode"
                    center
                    icon="mail"
                    type="primary"
                    :href="mailToUrl"
                    open-in-new-tab
                >
                    {{ $t("add_people_easy_access_send") }}
                </app-button>
                <div class="divider mtop-medium mbottom-medium">
                    <span>{{ $t("or") }}</span>
                </div>
            </loading-wrapper>
        </transition-expand>
        <p class="mbottom-xxsmall">
            <strong>{{ $t("add_people_invite_title") }}</strong>
        </p>
        <p>{{ $t("add_people_invite_description") }}</p>
        <MailInvite/>
    </div>
</template>

<script>
import MailInvite from "@/views/intranet/invite/MailInvite";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET } from "@/store/intranet/getters";
import { codeBasedIntranetAccessService } from "@/services";
import TransitionExpand from "@/components/TransitionExpand";
import { buildMailToUrl } from "@/lib/mail-to";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import { CodeBasedIntranetAccessService } from "@/services/intranet-access";

export default {
    name: "AddPeopleModal",
    components: { TransitionExpand, MailInvite },
    data() {
        return {
            accessCode: null,
            loadingAccessCode: true,
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
        easyAccessDescription() {
            if (this.accessCode) {
                return this.$t("add_people_easy_access_enabled_description", [this.intranet.displayName]);
            }
            return this.$t("add_people_easy_access_disabled_description");
        },
        mailToUrl() {
            return buildMailToUrl({
                subject: this.$t("add_people_easy_access_mailto_subject", [this.intranet.displayName]),
                body: this.$t("add_people_easy_access_mailto_body", [
                    this.intranet.displayName,
                    CodeBasedIntranetAccessService.buildJoinUrl({
                        intranetUid: this.intranet.uid,
                        accessCode: this.accessCode,
                    }),
                    this.currentUser.displayName,
                ]),
            });
        }
    },
    async created() {
        try {
            const credentials = await codeBasedIntranetAccessService.getIntranetAccessCredentials(this.intranet.uid);
            this.accessCode = credentials.accessCode;
        } finally {
            this.loadingAccessCode = false;
        }
    },
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
    ::v-deep {
        .indicator {
            margin: $spacing-large 0;
        }
    }
}
</style>
