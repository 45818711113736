interface TriggerMailToParams {
    to?: string;
    cc?: string;
    bcc?: string;
    subject?: string;
    body?: string;
}

export function buildMailToUrl({ to = "", cc = "", bcc = "", subject = "", body = "" }: TriggerMailToParams): string {
    return `mailto:${encodeURIComponent(to)}?cc=${encodeURIComponent(cc)}&bcc=${encodeURIComponent(bcc)}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
}
