<template>
    <div class="pbottom-large">
        <div class="people-header mbottom-medium">
            <div>
                <SearchInput
                    :placeholder="$t('search_for_name_or_mail')"
                    class="mbottom-medium searchbar"
                    @input="search"
                />
            </div>
            <div>
                <app-button
                    v-if="isAdmin"
                    type="primary"
                    icon="user-plus"
                    @click="openInviteModal"
                >
                    {{ $t("add_people") }}
                </app-button>
                <app-button
                    v-if="isAdmin"
                    type="light"
                    class="mobile-hidden"
                    @click="openDrawer"
                >
                    {{ $t("invites") }}
                </app-button>
            </div>
        </div>

        <LoadingWrapper :is-loading="initialLoading">
            <div class="userlist">
                <transition-group name="infinite-list-group">
                    <UserRow
                        v-for="(user, i) in users"
                        :key="user.uid"
                        :user="user"
                        :style="{'--delay': i * 50 + 'ms'}"
                        @delete="triggerDelete"
                    />
                </transition-group>
                <SystemMessage
                    v-if="users.length === 0"
                    class="not-found-message"
                    type="results"
                    :title="$t('try_a_different_query')"
                >
                    {{ $t("no_results_user_search", [intranet.displayName]) }}
                </SystemMessage>
                <InfiniteScrollSpinner
                    v-if="isNextPage"
                    :loading="loading"
                    @load-more="triggerLoadMore"
                />
                <div class="totalcount padding-medium centered meta">
                    {{ $tc("results_tc", nbHits, [nbHits]) }}
                </div>
            </div>
        </LoadingWrapper>

        <Drawer
            name="invites-drawer"
            size="medium"
        >
            <div class="grid flex-center mbottom-small">
                <div class="left">
                    <h3>{{ $t("invites") }}</h3>
                </div>
                <div class="right">
                    <app-button
                        v-if="isAdmin"
                        type="primary"
                        icon="user-plus"
                        @click="openInviteModal"
                    >
                        {{ $t("add_people") }}
                    </app-button>
                </div>
            </div>
            <InviteList/>
        </Drawer>
    </div>
</template>

<script>
import Vue from "vue";
import {
    mapActions,
    mapGetters,
    mapState,
} from "vuex";
import { PEOPLE_MODULE_NAME } from "@web/store/people/people";
import UserRow from "@web/views/intranet/directory/UserRow.vue";
import { IS_NEXT_PAGE } from "@web/store/people/getters";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    INTRANET_UID,
    IS_ADMIN,
} from "@web/store/intranet/getters";
import {
    FETCH_INITIAL_PAGE,
    FETCH_NEXT_PAGE,
    REMOVE_USER,
} from "@web/store/people/actions";
import SearchInput from "@web/components/content-search/SearchInput.vue";
import InfiniteScrollSpinner from "@web/components/InfiniteScrollSpinner";
import InviteList from "@web/views/intranet/invite/InviteList";
import Drawer from "@web/components/modals/Drawer";
import AddPeopleModal from "@/views/intranet/directory/AddPeopleModal";

export default Vue.extend({
    name: "UserList",
    components: { InviteList, SearchInput, UserRow, InfiniteScrollSpinner, Drawer },
    data() {
        return {
            searchTerm: "",
            loading: false,
        };
    },
    computed: {
        ...mapState(PEOPLE_MODULE_NAME, ["users", "initialLoading", "nbHits", "nbPages", "page"]),
        ...mapGetters(INTRANET_MODULE_NAME, { intranetUid: INTRANET_UID, intranet: "intranet" }),
        ...mapGetters({
            isNextPage: PEOPLE_MODULE_NAME + IS_NEXT_PAGE,
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
        }),
    },
    methods: {
        ...mapActions({
            fetchNextPage: PEOPLE_MODULE_NAME + FETCH_NEXT_PAGE,
            fetchInitialPage: PEOPLE_MODULE_NAME + FETCH_INITIAL_PAGE,
            deleteUser: PEOPLE_MODULE_NAME + REMOVE_USER,
        }),
        async initSearch() {
            await this.fetchInitialPage({ query: this.searchTerm });
        },
        async triggerLoadMore() {
            this.loading = true;
            await this.fetchNextPage({ query: this.searchTerm });
            this.loading = false;
        },
        search(newTerm) {
            if (this.searchTerm === newTerm) {
                return; // prevents spamming requests when user hitting enter for same query
            }
            this.searchTerm = newTerm;
            this.initSearch();
        },
        async triggerDelete(user) {
            await this.deleteUser(user.uid)
                .then(() => {
                    this.$notify({
                        group: "app",
                        type: "success",
                        text: this.$t("remove_user_success"),
                    });
                })
                .catch(e => {
                    this.$notify({
                        group: "app",
                        type: "error",
                        text: this.$t("remove_user_error"),
                    });
                    console.error(e);
                });
        },
        openDrawer() {
            this.$modal.show("invites-drawer");
        },
        openInviteModal() {
            this.$modal.show("modal", {
                component: AddPeopleModal,
                modalProps: {
                    title: this.$t("add_people"),
                },
            });
        },
    },
    created() {
        this.fetchInitialPage({ query: this.searchTerm });
    },
});
</script>

<style lang="scss" scoped>
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.searchbar {
    min-width: 250px;
    max-width: 400px;
}

.people-header {
    display: flex;
    justify-content: space-between;

    .button {
        vertical-align: middle;

        ::v-deep {
            .inner {
                height: 24px;
            }
        }
    }
}
</style>
