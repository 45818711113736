<template>
    <div class="pbottom-large">
        <LoadingWrapper :is-loading="loading">
            <!--
            feature currently disabled as backend does not provide sufficient results
            <input type="text"
                    :placeholder="$t('enter_mail_to_search_for')"
                    v-model="searchTerm"
                    @keyup.enter.prevent="search(searchTerm)"/>
            <p v-if="lastSearchTerm">
                {{ $t("search_results_for") }} {{ lastSearchTerm }} (<a
                    href="#"
                    @click.prevent="resetSearch()">{{ $t("reset") }})</a>
            </p>
            -->
            <div
                v-if="invites.length > 0"
                class="invite-list"
            >
                <transition-group
                    name="fade"
                    mode="out-in"
                >
                    <InviteRow
                        v-for="invite in invites"
                        :key="invite.inviteId"
                        :invite="invite"
                    />
                </transition-group>
            </div>
            <SystemMessage
                v-else
                type="invites"
            />
            <div>
                <a
                    v-if="page > 0"
                    href="#"
                    style="padding-right: 10px"
                    @click.prevent="fetchInvites('prev')"
                >{{ $t("prev_page") }}</a>
                <a
                    v-if="isNextPage"
                    href="#"
                    @click.prevent="fetchInvites('next')"
                >{{
                    $t("next_page")
                }}</a>
            </div>
        </LoadingWrapper>
    </div>
</template>

<script>
import Vue from "vue";
import {
    mapActions,
    mapState
} from "vuex";
import { INVITE_MODULE_NAME } from "@web/store/invite/invite";
import {
    FETCH_INVITES,
    SEARCH_BY_MAIL
} from "@web/store/invite/actions";
import InviteRow from "@web/views/intranet/invite/InviteRow.vue";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export default {
    name: "InviteList",
    components: { InviteRow },
    data() {
        return {
            searchTerm: "",
            lastSearchTerm: "",
        };
    },
    computed: {
        ...mapState(INVITE_MODULE_NAME, ["invites", "loading", "isNextPage", "page"]),
    },
    methods: {
        ...mapActions({
            fetchInvites: INVITE_MODULE_NAME + FETCH_INVITES,
            searchByMail: INVITE_MODULE_NAME + SEARCH_BY_MAIL,
        }),
        search() {
            this.searchByMail(this.searchTerm);
            this.lastSearchTerm = this.searchTerm;
        },
        resetSearch() {
            this.searchTerm = undefined;
            this.search();
        },
    },
    created() {
        analytics.log(getGlobalConfiguration().analytics_event_name_people_invite_list_opened);
        if (this.invites.length === 0) {
            this.fetchInvites("next");
        }
    }
};
</script>

<style lang="scss" scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }
</style>
